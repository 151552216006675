<template>
    <layout>
        <div class="w-100">
            <div class="row justify-content-center">
                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                    <div class="mx-auto login-w-limit">
                        <div class="logo-wrapper text-center mb-2">
                            <img src="../../../assets/img/bau-logo-for-light.svg" height="35"/>
                        </div>
                        <h4 class="text-center mb-5" style="min-height: 50px;">
                            {{ $t('turkmer') }} {{ $t('application_system') }}
                        </h4>
                        <div class="fadeIn">
                            <ValidationObserver ref="form">
                                <div class="mx-auto">
                                    <ValidationProvider name="username" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('national_id')+' / '+$t('passport_number')">
                                            <b-form-input type="text"
                                                          v-model="loginForm.username"
                                                          :state="errors[0] ? false : null"/>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="pin" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('pin')">
                                            <password-input v-model="loginForm.pin" :validateError="errors[0]"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <div class="d-flex justify-content-center mb-4">
                                        <b-button variant="primary" block @click="login()">
                                            {{ $t('login').toUpper() }}
                                        </b-button>
                                    </div>
                                    <a class="text-center d-block text-muted cursor-pointer"
                                       @click="goToRegister()">
                                       {{ $t('first_password_get') }}
                                    </a>
                                    <a class="text-center d-block text-muted cursor-pointer mt-2"
                                       @click="goToForgot()">
                                       {{ $t('forgot_my_password') }}
                                    </a>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
            <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" size="invisible"
                                        sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG" />
        </div>
    </layout>
</template>

<script>
    // Layout
    import Layout from '@/modules/turkmerApplication/layout/Layout.vue'

    // Component
    import PasswordInput from '@/components/elements/PasswordInput.vue'

    // Service
    import TurkmerApplicationStudentService from '@/services/TurkmerApplicationStudentService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import Base from '@/plugins/Base'
    import { VueRecaptcha } from 'vue-recaptcha'

    export default {
        components: {
            PasswordInput,
            Layout,
            VueRecaptcha,
            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                loginForm: {
                    username: null,
                    pin: null
                }
            }
        },
        mounted() {
            this.$store.dispatch('turkmerApplication/isLoginGoIndex');
        },
        methods: {
            onCaptchaVerified(recaptchaToken) {
            this.loginForm.g_recaptcha_token = recaptchaToken;
            TurkmerApplicationStudentService.login(this.loginForm)
                .then(response => {
                    let data = response.data.data;

                    // Data
                    if (data) {
                        this.$store.commit('turkmerApplication/setData', data)
                        Base.LocalStorage.set('turkmer_application_data', data)
                    }

                    // Username
                    let username = data.national_id ?
                        data.national_id :
                        data.passport_number;
                    if (username) {
                        this.$store.commit('turkmerApplication/setUsername', username)
                        Base.LocalStorage.set('turkmer_application_username', username)
                    }

                    // Pin
                    if (data.pin) {
                        this.$store.commit('turkmerApplication/setPin', data.pin)
                        Base.LocalStorage.set('turkmer_application_pin', data.pin)
                    }
                })
                .then(() => {
                    this.$router.push('/turkmer');
                })
                .catch((e) => {
                    this.showErrors(e);
                }).finally(() => {
                    if (this.$refs.recaptcha) {
                        this.$refs.recaptcha.reset();
                    }
                })
            },
            async login() {
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    this.$refs.recaptcha.execute()
                }
            },
            goToRegister() {
                this.$router.push('/turkmer/register');
            },
            goToForgot() {
                this.$router.push('/turkmer/forgot-password');
            },
        }
    }
</script>
